import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomePage.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomeView
    },
    {
        path: "/CompanyPage",
        name: "CompanyPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/CompanyPage.vue"),
    },
    {
        path: "/ProductPage",
        name: "ProductPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/ProductPage.vue"),
    },
    {
        path: "/KnowlagePage",
        name: "KnowlagePage",
        component: () => import(/* webpackChunkName: "about" */ "../views/KnowlagePage.vue"),
    },
    {
        path: "/SearchPage",
        name: "SearchPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/SearchPage.vue"),
    },
    {
        path: "/ContactPage",
        name: "ContactPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/ContactPage.vue"),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;