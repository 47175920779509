<template>
    <div>
        <div class="banner">
            <img src="../assets/imgs/common/a1_r1_c1.jpg" alt="">
        </div>
        <div class="head-bar">
            <router-link v-for="(item,index) in menus" :key="index" :to="item.path">
                <img :src="$route.path==item.path?item.activeImg:item.nomalImg" alt="">
            </router-link>
        </div>
    </div>
</template>

<script setup>
const menus = [
    {
        name:'首页',
        path: '/',
        nomalImg: require('../assets/imgs/common/caidanb1.jpg'),
        activeImg: require('../assets/imgs/common/caidana1.jpg')
    },
    {
        name:'走进国和',
        path:'/CompanyPage',
        nomalImg: require('../assets/imgs/common/caidanb2.jpg'),
        activeImg: require('../assets/imgs/common/caidana2.jpg')
    },
    {
        name:'产品中心',
        path:'/ProductPage',
        nomalImg: require('../assets/imgs/common/caidanb3.jpg'),
        activeImg: require('../assets/imgs/common/caidana3.jpg')
    },
    {
        name:'黄金知识',
        path:'/KnowlagePage',
        nomalImg: require('../assets/imgs/common/caidanb5.jpg'),
        activeImg: require('../assets/imgs/common/caidana5.jpg')
    },
    {
        name:'真伪查询',
        path:'/SearchPage',
        nomalImg: require('../assets/imgs/common/caidanb7.jpg'),
        activeImg: require('../assets/imgs/common/caidana7.jpg')
    },
    {
        name:'联系我们',
        path:'/ContactPage',
        nomalImg: require('../assets/imgs/common/caidanb6.jpg'),
        activeImg: require('../assets/imgs/common/caidana6.jpg')
    },
]
</script>

<style scoped>
.banner{
    height: 91px;
}
.head-bar{
    height: 41px;
    padding-top: 0px;
    background: url(~@/assets/imgs/common/a1_r2_c2.jpg) repeat-x;
}
</style>