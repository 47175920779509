<template>
    <div class="foot">
        <div class="footbar">
            <router-link v-for="(item,index) in menus" :key="index" :to="item.path">
                <span class="footlink"> {{ item.name }} </span>
            </router-link>
        </div>
        <div>
            <p>版权所有  国和金证(北京)黄金制品有限公司</p>
            <p>公司地址:北京市海淀区紫竹院路88号紫竹花园D座1004室</p>
            <p>公司电话:010-88829488  传真:010-88829368     Email:guohejinzheng@126.com</p>
            <p>技术支持:
                <a href="https://www.aviewer.cn" target="_blank" >阅迹网</a>
            </p>
            <p><a href="https://beian.miit.gov.cn/" target="_blank" >京ICP备15016287号</a></p>
        </div>

    </div>
</template>

<script setup>
const menus = [
    {
        name:'人才招聘',
        path: '/',
    },
    {
        name:'销售热线',
        path: '/',
    },
    {
        name:'友情连接',
        path: '/',
    },
    // {
    //     name:'加入收藏',
    //     path: '/',
    // },
]
</script>

<style scoped>
.foot{
    font-family: Arial, Helvetica, sans-serif;
	line-height: 22px;
	color: #333;
	text-decoration: none;
    font-size: xx-small;
}
.footbar{
    background-color: #544E45;
    height: 25px;
    
}
.footlink{
    color: aliceblue;
    margin-left: 20px;
    margin-right: 20px;
    font-size: x-small;
    font-family: Arial, Helvetica, sans-serif;
	line-height: 22px;
}
a[href="https://www.aviewer.cn"]{
    color: #6f4d04; 
    font-family: Arial, Helvetica, sans-serif; 
    font-size: 14px;
    font-weight:900;
}
a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: #fff solid;
}
</style>